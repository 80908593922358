import React, { useState } from 'react';
import { Row, Col, Label } from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import TermsAndConditions from './TermsAndConditions';
import { useNavigate } from 'react-router-dom';
const ScholarshipEnroll = () => {
    
    const [formValues, setFormValues] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        mobile: '',
        currentGrade: '',
        incomeCertificate: '',
        passportPhoto: '',
        studentAadharCard: '',
        parentAadharCard: '',
        panCard: '',
        sscMarksheet: '',
        schoolLeavingCertificate: '',
        itReturn: '',
        deathCertificate: '',
        recommendationLetter: '',
        terms: false
    });

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormValues({ ...formValues, [name]: files[0] });
    };

    const validateForm = () => {

        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // const contactRegex = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
        // const contactRegex = /\d{5}([- ]*)\d{6}/;
        const contactRegex = /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/;

        if (!formValues.firstName) {
            errors.firstName = 'First Name is required';
        }
        if (!formValues.middleName) {
            errors.middleName = 'Middle Name is required';
        }
        if (!formValues.lastName) {
            errors.lastName = 'Last Name is required';
        }
        if (!formValues.email) {
            errors.email = 'Email is required';
        }else if (!regex.test(formValues.email)) {
            errors.email = "Invalid Email address!";
        } 

        if (!formValues.mobile) {
            errors.mobile = 'Mobile Number is required';
        }else if (!contactRegex.test(formValues.mobile)) {
            errors.mobile = "Invalid Mobile Number!";
        } 
        if (!formValues.currentGrade) {
            errors.currentGrade = 'Current Grade is required';
        }
        if (!formValues.incomeCertificate) {
            errors.incomeCertificate = 'Income Certificate is required';
        }
        if (!formValues.passportPhoto) {
            errors.passportPhoto = 'Passport Photo is required';
        }
        if (!formValues.studentAadharCard) {
            errors.studentAadharCard = 'Student Aadhar Card is required';
        }
        if (!formValues.parentAadharCard) {
            errors.parentAadharCard = 'Parent Aadhar Card is required';
        }
        if (!formValues.sscMarksheet) {
            errors.sscMarksheet = 'SSC Marksheet is required';
        }

        // Optional fields (validation not mandatory)

        if (formValues.terms === false) {
            errors.terms = 'Please Accept terms and condition';
        }

        setFormErrors(errors);

        console.log("ff", regex.test(formValues.email));
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmit(true);
        console.log("ll");
        
        const errors = validateForm();
        console.log(errors);
        
        if (Object.keys(errors).length === 0) {
            const formData = new FormData();
            
            formData.append("firstName", formValues.firstName);
            formData.append("middleName", formValues.middleName);
            formData.append("lastName", formValues.lastName);
            formData.append("email", formValues.email);
            formData.append("mobile", formValues.mobile);
            formData.append("currentGrade", formValues.currentGrade);
            formData.append("incomeCertificate", formValues.incomeCertificate);
            formData.append("passportPhoto", formValues.passportPhoto);
            formData.append("studentAadharCard", formValues.studentAadharCard);
            formData.append("parentAadharCard", formValues.parentAadharCard);
            formData.append("panCard", formValues.panCard);
            formData.append("sscMarksheet", formValues.sscMarksheet);
            formData.append("schoolLeavingCertificate", formValues.schoolLeavingCertificate);
            formData.append("itReturn", formValues.itReturn);
            formData.append("deathCertificate", formValues.deathCertificate);
            formData.append("recommendationLetter", formValues.recommendationLetter);
            formData.append("IsApproved", false);
            
            try {
                const res = await axios.post(`${process.env.REACT_APP_NAIDIP_FOUNDATION_URL}/api/auth/create/applicationsByFrontend`, formData);
                console.log(res.status);
                
                if (res.status === 200) {
                    alert("Thank you for Enrolling");
                    window.location.reload("/ScholarshipEnroll");
                    
                    // Reset form values
                    setFormValues({
                        firstName: '',
                        middleName: '',
                        lastName: '',
                        email: '',
                        mobile: '',
                        currentGrade: '',
                        incomeCertificate: '',
                        passportPhoto: '',
                        studentAadharCard: '',
                        parentAadharCard: '',
                        panCard: '',
                        sscMarksheet: '',
                        schoolLeavingCertificate: '',
                        itReturn: '',
                        deathCertificate: '',
                        recommendationLetter: '',
                        terms: false
                    });
                }
            } catch (error) {
                console.error("An error occurred during submission:", error.message);
                alert("An error occurred while submitting the form. Please try again after some time.");
            }
        }
    };
    

    return (
        <div>
        <div className="spacing-form">
          <Row className="spacing-form">
            <Col md="4">
              <Row className="margin-top">
                <Col md="6" className="padding-none">
                  <div className="grid-spacing1">
                    <strong><Link to="/Program">Programme</Link></strong>
                  </div>
                </Col>
                <Col md="6" className="padding-none">
                  <div className="grid-spacing2">
                    <strong><Link to="/Associates">Associates</Link></strong>
                  </div>
                </Col>
                <Col md="6" className="padding-none">
                  <div className="grid-spacing2">
                    <strong><Link to="/Benefits">Benefits</Link></strong>
                  </div>
                </Col>
                <Col md="6" className="padding-none">
                  <div className="grid-spacing1">
                    <strong><Link to="/Eligibility">Eligibility</Link></strong>
                  </div>
                </Col>
                <Col md="6" className="padding-none">
                  <div className="grid-spacing1">
                    <strong><Link to="/Criteria">Selection Criteria</Link></strong>
                  </div>
                </Col>
                <Col md="6" className="padding-none">
                  <div className="grid-spacing2">
                    <strong><Link to="/ScholarshipEnroll">Enroll Now</Link></strong>
                  </div>
                </Col>
                <Col md="6" className="padding-none">
                  <div className="grid-spacing2">
                    <strong><Link to="/Faq">FAQ</Link></strong>
                  </div>
                </Col>
                <Col md="6" className="padding-none">
                  <div className="grid-spacing1">
                    <strong><Link to="/Contact">Contact</Link></strong>
                  </div>
                </Col>
                <Col md="6" className="padding-none">
                  <div className="grid-spacing1">
                    <strong><Link to="/Disclaimer">Disclaimer</Link></strong>
                  </div>
                </Col>
                <Col md="6" className="padding-none">
                  <div className="grid-spacing2">
                    <strong><Link to="/SuccessStories" target="_blank">Success Stories</Link></strong>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="8">
            <div>
            <form >
                <Row className='text-left'>
                    <Col md="6" className="margin-top text-left">
                        <label className="label-form">First Name<span className="text-danger">*</span></label>
                        <input
                            name="firstName"
                            type="text"
                            className="form-control"
                            value={formValues.firstName}
                            onChange={handleInputChange}
                        />
                        {isSubmit && formErrors.firstName && (
                            <p className="text-danger">{formErrors.firstName}</p>
                        )}
                    </Col>
                    <Col md="6" className="margin-top">
                        <label className="label-form">Middle Name<span className="text-danger">*</span></label>
                        <input
                            name="middleName"
                            type="text"
                            className="form-control"
                            value={formValues.middleName}
                            onChange={handleInputChange}
                        />
                        {isSubmit && formErrors.middleName && (
                            <p className="text-danger">{formErrors.middleName}</p>
                        )}
                    </Col>
                    <Col md="6" className="margin-top">
                        <label className="label-form">Last Name<span className="text-danger">*</span></label>
                        <input
                            name="lastName"
                            type="text"
                            className="form-control"
                            value={formValues.lastName}
                            onChange={handleInputChange}
                        />
                        {isSubmit && formErrors.lastName && (
                            <p className="text-danger">{formErrors.lastName}</p>
                        )}
                    </Col>
                    <Col md="6" className="margin-top">
                        <label className="label-form">Email<span className="text-danger">*</span></label>
                        <input
                            name="email"
                            type="email"
                            className="form-control"
                            value={formValues.email}
                            onChange={handleInputChange}
                        />
                        {isSubmit && formErrors.email && (
                            <p className="text-danger">{formErrors.email}</p>
                        )}
                    </Col>
                    <Col md="6" className="margin-top">
                        <label className="label-form">Mobile<span className="text-danger">*</span></label>
                        <input
                            name="mobile"
                            type="text"
                            className="form-control"
                            value={formValues.mobile}
                            onChange={handleInputChange}
                        />
                        {isSubmit && formErrors.mobile && (
                            <p className="text-danger">{formErrors.mobile}</p>
                        )}
                    </Col>
                    <Col md="6" className="margin-top">
                        <label className="label-form">Current Grade<span className="text-danger">*</span></label>
                        <select
                            name="currentGrade"
                            className="form-control"
                            value={formValues.currentGrade}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Grade</option>
                            <option value="10">10th</option>
                            <option value="11">11th</option>
                            <option value="12">12th</option>
                        </select>
                        {isSubmit && formErrors.currentGrade && (
                            <p className="text-danger">{formErrors.currentGrade}</p>
                        )}
                    </Col>
                </Row>

                <div className="col-md-12 col-lg-12 margin-top text-left">
                    <h4 className='custom'> Documents </h4>
                </div>

                <Row className="margin-top">
                    {/* Documents Section */}
                    {/* Income Certificate */}
                    <Row className="margin-top d-flex align-items-center">
                        <Col md="4" className='text-left'>
                            <label className="label-form">Income Certificate<span className="text-danger">*</span></label>
                        </Col>
                        <Col md="8">
                            <input
                                name="incomeCertificate"
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                            />
                            {isSubmit && formErrors.incomeCertificate && (
                                <p className="text-danger">{formErrors.incomeCertificate}</p>
                            )}
                        </Col>
                    </Row>

                    {/* Passport Photo */}
                    <Row className="margin-top d-flex align-items-center">
                        <Col md="4" className='text-left'>
                            <label className="label-form">Passport Photo<span className="text-danger">*</span></label>
                        </Col>
                        <Col md="8">
                            <input
                                name="passportPhoto"
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                            />
                            {isSubmit && formErrors.passportPhoto && (
                                <p className="text-danger">{formErrors.passportPhoto}</p>
                            )}
                        </Col>
                    </Row>

                    {/* Student Aadhar Card */}
                    <Row className="margin-top d-flex align-items-center">
                        <Col md="4" className='text-left'>
                            <label className="label-form">Student Aadhar Card<span className="text-danger">*</span></label>
                        </Col>
                        <Col md="8">
                            <input
                                name="studentAadharCard"
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                            />
                            {isSubmit && formErrors.studentAadharCard && (
                                <p className="text-danger">{formErrors.studentAadharCard}</p>
                            )}
                        </Col>
                    </Row>

                    {/* Parent Aadhar Card */}
                    <Row className="margin-top d-flex align-items-center">
                        <Col md="4" className='text-left'>
                            <label className="label-form">Parent Aadhar Card<span className="text-danger">*</span></label>
                        </Col>
                        <Col md="8">
                            <input
                                name="parentAadharCard"
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                            />
                            {isSubmit && formErrors.parentAadharCard && (
                                <p className="text-danger">{formErrors.parentAadharCard}</p>
                            )}
                        </Col>
                    </Row>

                    {/* SSC Marksheet */}
                    <Row className="margin-top d-flex align-items-center">
                        <Col md="4" className='text-left'>
                            <label className="label-form">SSC Marksheet<span className="text-danger">*</span></label>
                        </Col>
                        <Col md="8">
                            <input
                                name="sscMarksheet"
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                            />
                            {isSubmit && formErrors.sscMarksheet && (
                                <p className="text-danger">{formErrors.sscMarksheet}</p>
                            )}
                        </Col>
                    </Row>

                    {/* Optional Fields */}
                    {/* PAN Card */}
                    <Row className="margin-top d-flex align-items-center">
                        <Col md="4" className='text-left'>
                            <label className="label-form">PAN Card</label>
                        </Col>
                        <Col md="8">
                            <input
                                name="panCard"
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                            />
                        </Col>
                    </Row>

                    {/* School Leaving Certificate */}
                    <Row className="margin-top d-flex align-items-center">
                        <Col md="4" className='text-left'>
                            <label className="label-form">School Leaving Certificate</label>
                        </Col>
                        <Col md="8">
                            <input
                                name="schoolLeavingCertificate"
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                            />
                        </Col>
                    </Row>

                    {/* IT Return */}
                    <Row className="margin-top d-flex align-items-center">
                        <Col md="4" className='text-left'>
                            <label className="label-form">IT Return</label>
                        </Col>
                        <Col md="8">
                            <input
                                name="itReturn"
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                            />
                        </Col>
                    </Row>

                    {/* Death Certificate */}
                    <Row className="margin-top d-flex align-items-center">
                        <Col md="4" className='text-left'>
                            <label className="label-form">Death Certificate (if applicable)</label>
                        </Col>
                        <Col md="8">
                            <input
                                name="deathCertificate"
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                            />
                        </Col>
                    </Row>

                    {/* Recommendation Letter */}
                    <Row className="margin-top d-flex align-items-center">
                        <Col md="4" className='text-left'>
                            <label className="label-form">Recommendation Letter</label>
                        </Col>
                        <Col md="8">
                            <input
                                name="recommendationLetter"
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                            />
                        </Col>
                    </Row>
                </Row>


                <Row>
                    <Col md="12" className='d-flex'>
                        <input
                        className='m-0'
                            type="checkbox"
                            name="terms"
                            onChange={handleInputChange}
                        //   value={terms}
                        //   className="form-control"
                        />{' '}
                        <Label className='m-0 pl-2'>I have read and agreed to the{' '}
                            <Link to='/TermsAndConditions' target='_blank'  rel="noopener noreferrer" className='m-0 text-primary'>
                                Terms & Conditions
                            </Link></Label>

                    </Col>

                    {isSubmit && formErrors.terms && (
                        <p className="text-danger">{formErrors.terms}</p>
                    )}
                </Row>
                <Col md="12" className="margin-top text-right">
                    <button type="submit" className="submit-btn " onClick={handleSubmit} >
                        Submit
                    </button>
                </Col>

            </form>
        </div>
            </Col>
          </Row>
        </div>
      </div>
    );
};

export default ScholarshipEnroll;
