import React from 'react';
import { Container, Row, Col } from 'reactstrap';


const Footer = () => {
  return (
    <footer>
      <div className="footer-area-bottom">
         
          <Row>
            <Col lg="6" sm="12" xs="12" className="text-lg-left text-center mb-2 mb-lg-0 pull-left">
              <div className="copyright">
                <p className='text-center'>
                  Copyright © 2023 <a href="#">Naidip Foundation</a> All Rights Reserved
                </p>
              </div>
            </Col>
            <Col lg="6" sm="12" xs="12" className="text-lg-right text-center pull-right">
              <div className="copyright">
                <p className='text-center'>
                  Developed And Designed By: <a href="http://www.barodaweb.com/" target="_blank" rel="noopener noreferrer">Barodaweb | The E-Catalogue Designer</a>
                </p>
              </div>
            </Col>
          </Row>
         
      </div>
    </footer>
  );
};

export default Footer;
