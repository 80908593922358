import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, NavbarToggler, Collapse, Nav as NavBar, NavItem, NavLink } from "reactstrap";
import { Puff } from "react-loader-spinner";
import banner from "../assets/img/banner.jpg";
import { FaBars, FaTimes } from "react-icons/fa";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get the current location
  const [activePath, setActivePath] = useState(location.pathname); // Track the active path

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  useEffect(() => {
    setActivePath(location.pathname); // Update the active path whenever the route changes
  }, [location]);

  const handleNavItemClick = (path) => {
    setActivePath(path);   // Set active path
    setIsOpen(false);      // Close the menu after clicking a link
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-container">
          <Puff color="#c7a17a" height={50} width={50} timeout={0} />
        </div>
      ) : (
        <>
          <div className="item">
            <img src={banner} alt="Banner" />
          </div>
          <header>
            <div id="sticker" className="bg-custom">
              <div className="m-0 container center-class">
                <Navbar expand="md" className="h-100 border-0 pt-0 navbar-default">
                  <NavbarToggler onClick={toggle} className="m-0 p-0">
                    {isOpen ? (
                      <FaTimes color="black" size={24} className="m-2" />
                    ) : (
                      <FaBars color="black" size={24} className="m-2" />
                    )}
                  </NavbarToggler>

                  <Collapse isOpen={isOpen} navbar className="p-0 m-0">
                    <NavBar className="ml-auto" navbar>
                      <NavItem className={`hvr-color ${activePath === "/Program" ? "active" : ""}`}>
                        <Link to="/Program" onClick={() => handleNavItemClick("/Program")}>
                          <NavLink>Programme</NavLink>
                        </Link>
                      </NavItem>
                      <NavItem className={`hvr-color ${activePath === "/Associates" ? "active" : ""}`}>
                        <Link to="/Associates" onClick={() => handleNavItemClick("/Associates")}>
                          <NavLink>Associates</NavLink>
                        </Link>
                      </NavItem>
                      <NavItem className={`hvr-color ${activePath === "/Benefits" ? "active" : ""}`}>
                        <Link to="/Benefits" onClick={() => handleNavItemClick("/Benefits")}>
                          <NavLink>Benefits</NavLink>
                        </Link>
                      </NavItem>
                      <NavItem className={`hvr-color ${activePath === "/Eligibility" ? "active" : ""}`}>
                        <Link to="/Eligibility" onClick={() => handleNavItemClick("/Eligibility")}>
                          <NavLink>Eligibility</NavLink>
                        </Link>
                      </NavItem>
                      <NavItem className={`hvr-color ${activePath === "/Criteria" ? "active" : ""}`}>
                        <Link to="/Criteria" onClick={() => handleNavItemClick("/Criteria")}>
                          <NavLink>Selection Criteria</NavLink>
                        </Link>
                      </NavItem>
                      <NavItem className={`hvr-color ${activePath === "/ScholarshipEnroll" ? "active" : ""}`}>
                        <Link to="/ScholarshipEnroll" onClick={() => handleNavItemClick("/ScholarshipEnroll")}>
                          <NavLink>Enroll Now</NavLink>
                        </Link>
                      </NavItem>
                      <NavItem className={`hvr-color ${activePath === "/Faq" ? "active" : ""}`}>
                        <Link to="/Faq" onClick={() => handleNavItemClick("/Faq")}>
                          <NavLink>FAQ</NavLink>
                        </Link>
                      </NavItem>
                      <NavItem className={`hvr-color ${activePath === "/Contact" ? "active" : ""}`}>
                        <Link to="/Contact" onClick={() => handleNavItemClick("/Contact")}>
                          <NavLink>Contact</NavLink>
                        </Link>
                      </NavItem>
                      <NavItem className={`hvr-color ${activePath === "/Disclaimer" ? "active" : ""}`}>
                        <Link to="/Disclaimer" onClick={() => handleNavItemClick("/Disclaimer")}>
                          <NavLink>Disclaimer</NavLink>
                        </Link>
                      </NavItem>
                    </NavBar>
                  </Collapse>
                </Navbar>
              </div>
            </div>
          </header>
        </>
      )}
    </React.Fragment>
  );
};

export default Home;
