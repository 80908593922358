import React from "react";
import { Col, Row } from "react-bootstrap";
import logo from "../assets/img/naidip-logo.png"

const Header = () => {
    return (
        <React.Fragment>
            <div className="topbar-area" >
            <div className="">
                <Row style={{marginLeft:'35px'}}>
                    <Col md={2} sm={2} xs={4} lg={10} className="p-0">
                        <div style={{ textAlign: "left" }} >
                            <a href="/Program">
                                <img src={logo} className="header-logo" alt="Logo" />
                            </a>
                        </div>
                    </Col>
                </Row>
            </div></div>
        </React.Fragment>
    );
};

export default Header;
