import React from 'react';

const SuccessStories = () => {
  const data = [
    {
      name: 'Rathwa Jayshriben Kanubhai',
      dob: '3/18/2008',
      admitted: 'Class-11 (Science)',
      percentage: '75%',
      familyInfo:
        'Original Deria, dt. Pavijetpur, Dist. The daughter of tribal parents living in a half-baked house at Chotaudepur - father Kanubhai worked in agriculture after graduation. While mother Bachiben passed 10th standard and does housework. Among their four children, 2 sons and 2 daughters, the third child is Jayashriben. She studied in boarding school from 5th and passed in 10th with 75%, but this school provides education only up to 10th standard, no suitable science stream school nearby and high fee and accommodation in the city cant be afforded. At the time of her confusion, she got admission to this school after receiving information about this scholarship scheme.',
      future: 'To become a doctor and serve with financial gain.',
      imageUrl: 'http://shaktikrupa.org//Upload/SuccessStories/1.png'
    },
    {
      name: 'Vasava Bhumikaben Kaliyabhai',
      dob: '7/13/2008',
      admitted: 'Class-11 (Science)',
      percentage: '67%',
      familyInfo:
        'Daughter of tribal parents residing at Father Kaliyabhai B.Adv Education Origin Piplod, Dated. Dedia Pada, Dist. Narmada Curry runs a small seed-pesticide shop at Dedyapada. While mother - Jethuben studied till 7th grade and does housework. Among their three children, 1 son and 2 daughters, the second child is Bhumika. She studied at Netrang from 5th standard boarding and passed 10th with 67%.',
      future: 'To become a doctor and serve with financial gain.',
      imageUrl: 'http://shaktikrupa.org/Upload/SuccessStories/2.png'
    },
    {
      name: 'Vasava Bhumikaben Kantilal',
      dob: '2/5/2008',
      admitted: 'Class-11 (Science)',
      percentage: '76%',
      familyInfo:
        'Original Pansar, dt. Dediyapada, G. The daughter of tribal parents living in a bamboo thatched house at Narmada - father Kantilalbhai studied in class 10 and worked as a farmer at home. While mother - Anjanaben studied till Gho-8 and does housework. Among their 3 children, Bhoomi is the first child among 3 daughters. Gho at Netrang - 5th Boarding studies and passed 10th with 76%. At a time of confusion as to what to do next, as the cost could not be borne, she got admission to this school after getting information about this scholarship scheme.',
      future: 'To become a doctor and serve with financial gain.',
      imageUrl: 'http://shaktikrupa.org/Upload/SuccessStories/3.png'
    }
  ];

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12 col-md-12 col-12">
          <h2 className="main-title text-center">
            A free donor-funded scheme for the higher-education of bright girls
          </h2>
          <p className="sub-title">
            Shri Chhotubhai A., a scheme awarded by donors from home and abroad for the higher
            education of bright girls from needy families of Vadodara hinterland district of Gujarat
            state. Patel Learning Institute - Started in Motafofalia School.
          </p>
          <p className="sub-title">
            This year 7 underprivileged girls have been admitted to class 11 (Science) in Shree
            Chotubhai A Patel Learning Institute – Motafofalia run by Shaktikrupa Charitable Trust
            and efforts are being made to enable them to get admission in higher educational studies
            and their overall development. Following are the details of students who have got
            admission in this Puskrit Yajna.
          </p>
        </div>

        {data.map((student, index) => (
          <table className="row success-block" key={index}>
            <tbody>
              <tr className="col-lg-12">
                <td>
                  <h2 className="name">{student.name}</h2>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="col-lg-3 text-center">
                    <img className="image-size" src={student.imageUrl} alt={student.name} />
                  </div>
                  <div className="col-lg-8">
                    <div className="margin-above">
                      <p>
                        <span className="details">Date of Birth:</span>
                        <span className="details-inner">{student.dob}</span>
                      </p>
                      <p>
                        <span className="details">Admitted:</span>
                        <span className="details-inner">{student.admitted}</span>
                      </p>
                      <p>
                        <span className="details">Class-10 Achievement:</span>
                        <span className="details-inner">{student.percentage}</span>
                      </p>
                      <p>
                        <p className="details">Family Information:</p>
                        <p>{student.familyInfo}</p>
                      </p>
                      <p>
                        <p className="details">Future ambition of the student:</p>
                        <p className="details">{student.future}</p>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
      </div>
    </div>
  );
};

export default SuccessStories;
