import React, { useState } from 'react';
import { Button } from 'reactstrap';
import axios from 'axios';

const NewsLetter = () => {
  const [email, setEmail] = useState("");

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubscribe = () => {
    if (!email) {
      alert("Email field cannot be empty.");
      return;
    }
    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    console.log("Subscribed email:", email);
    handleSubmit();
  };

  const handleSubmit = async () => {
    const values = {
        IsActive: true,
        email: email,
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_NAIDIP_FOUNDATION_URL}/api/auth/create/NewsLetter`, values);
        console.log(res.status);
        
        if (res.status === 200) {
            alert("Thank you for subscribing to our newsletter");
            setEmail("");
        }
    } catch (error) {
        console.error("An error occurred during subscription:", error);
        alert("An error occurred while subscribing to the newsletter. Please try again.");
    }
};

  return (
    <React.Fragment>
     <div className="container-fluid p-5" style={{ background: '#ffeede', justifyContent: 'center' }}>
  <div className="row">
    {/* Text Column */}
    <div className="col-lg-6 col-12 d-flex align-items-center justify-content-center mb-3">
      <p className="p-0 m-0">
        Sign up for our newsletter to receive the latest news and updates.
      </p>
    </div>

    {/* Input and Button Column */}
    <div className="col-lg-6 col-12 d-flex flex-column flex-sm-row p-2 h-100">
      {/* Email Input */}
      <div className="col-12 col-sm-8  mb-lg-0 h-100 p-0 me-2">
        <input 
          className="subscribe w-100 " 
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email} 
          placeholder="Enter email" 
        />
      </div>

      {/* Subscribe Button */}
      <div className="col-12 col-sm-4 d-flex join-now mt-sm-0 mt-4  justify-content-center">
        <Button className="border-0 join-now-button bg-transparent w-100 " type="button" onClick={handleSubscribe}>
          <h4 className='font5'>Subscribe now</h4>
        </Button>
      </div>
    </div>
  </div>
</div>

    </React.Fragment>
  );
};

export default NewsLetter;
